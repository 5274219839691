import React from "react"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"

import { Container, Row, Badge } from "react-bootstrap"

const TagsPage = ({
  data: {
    allBloggerPost: { group },
  },
}) => (
  <Layout>
    <SEO title="Tags" />
    <Helmet title={`Tags`} />
    <Container fluid>
      <Row>
        {group.map(tag => (
          <h3 key={tag.fieldValue}>
            <Badge
              pill
              className="m-4 cl-fundo bg-marinho hovbg-vinho"
              as={Link}
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
            >
              {tag.fieldValue} ({tag.totalCount})
            </Badge>
          </h3>
        ))}
      </Row>
    </Container>
  </Layout>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    allBloggerPost(limit: 1000) {
      group(field: labels) {
        fieldValue
        totalCount
      }
    }
  }
`
